export const navLinks = [
  {
    title: 'Pricing',
    href: '/pricing',
  },
  // {
  //   title: 'Demo',
  //   href: '',
  //   external: true,
  // },
  // {
  //   title: 'Blog',
  //   href: '/blog',
  // },
  {
    title: 'Legal',
    href: '/legal/privacy-policy',
  },
];
