/* eslint-disable tailwindcss/no-custom-classname */
import { useState } from 'react';
import { Button } from '@repo/ui/button';
import { navLinks } from './navLinks';
import logo from '@/images/concord-google-blue-white.svg';

export const MobileMenuButton = () => {
  const [openMenu, setOpenMenu] = useState(false);

  return (
    <aside className="lg:hidden">
      <Button
        variant="ghost"
        size="icon"
        className="shrink-0 lg:hidden"
        onClick={() => setOpenMenu(!openMenu)}
      >
        <i className="ph ph-list text-2xl text-muted-foreground"></i>
        <span className="sr-only">Toggle navigation menu</span>
      </Button>

      <div
        className={`fixed inset-0 z-40 bg-black/50 backdrop-blur-sm ${openMenu ? 'visible' : 'invisible'}`}
      >
        <nav className="fixed inset-x-0 top-0 z-50 flex flex-col gap-6 border-b border-b-border bg-black/75 p-4 px-5 text-lg sm:px-6">
          <div className="flex items-center justify-between">
            <img src={logo.src} alt="Concord x Google Logo" />
            <Button
              variant="ghost"
              size="icon"
              onClick={() => setOpenMenu(!openMenu)}
            >
              <i className="ph ph-x text-2xl text-muted-foreground"></i>
              <span className="sr-only">Close navigation menu</span>
            </Button>
          </div>
          <ul
            className={`flex flex-col gap-6 ${openMenu ? 'translate-x-0' : 'translate-x-full'}`}
          >
            {navLinks.map((link) => (
              <li key={link.href}>
                <a href={link.href} className="text-white hover:text-primary">
                  {link.title}
                  <span className="sr-only">{link.title}</span>
                </a>
              </li>
            ))}
            {/* Sign In and Sign Up buttons */}
            <Button variant="outline" className="w-full">
              Sign In
            </Button>
            <Button className="w-full">Sign Up</Button>
          </ul>
        </nav>
      </div>
    </aside>
  );
};
