import React, { useState, useEffect } from 'react';
import { Button } from '@repo/ui/button';
import { navLinks } from './navLinks';
import { MobileMenuButton } from './mobile-menu-button';
import logo from '@/images/concord-google-blue-white.svg';

export const Navbar: React.FC = () => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 20);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div
      className={`fixed inset-x-0 top-0 z-50 w-full items-center px-5 py-4 shadow-md animate-in fade-in sm:px-6${
        scrolled
          ? 'border-b border-primary/[0.2] bg-black/75'
          : 'border-transparent bg-transparent'
      }`}
    >
      <nav className="mx-auto max-w-screen-xl items-center">
        <div className="flex items-center justify-between">
          {/* Concord Logo */}
          <a href="/">
            <div className="flex items-center">
              <img src={logo.src} alt="Concord x Google Logo" />
            </div>
          </a>

          {/* Navigation Links & Buttons */}
          <div className="hidden items-center gap-14 text-sm font-semibold lg:flex">
            <div className="flex gap-14">
              {navLinks.map((link) => (
                <a
                  key={link.href}
                  href={link.href}
                  className="hover:text-primary"
                  // target={link.external ? '_blank' : undefined}
                  // rel={link.external ? 'noopener noreferrer' : undefined}
                >
                  {link.title}
                </a>
              ))}
            </div>
            <div className="flex items-center gap-7">
              {/* Sign In and Sign Up buttons */}
              <a href="" target="_blank" rel="noopener noreferrer">
                <Button variant="outline">Sign In</Button>
              </a>
              <a href="" target="_blank" rel="noopener noreferrer">
                <Button variant="primaryOutline">Sign Up</Button>
              </a>
            </div>
          </div>

          {/* Hamburger Button for Mobile Menu */}
          <MobileMenuButton />
        </div>
      </nav>
    </div>
  );
};
